.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-video {
  width: 100%;
  height: 0px;
  position: relative;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.home-hero-text {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading {
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-text {
  color: var(--dl-color-landing);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  width: 75%;
}
.home-navlink {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-text1 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.home-text2 {
  color: var(--dl-color-landing);
  width: 75%;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-navlink1 {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.home-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.home-hero {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
}
.home-text3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-hero1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
}
.home-text4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.home-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
@media (max-width: 991px) {
  .home-video {
    height: 382px;
  }
  .home-hero-text {
    width: 80%;
  }
  .home-text2 {
    width: 80%;
  }
  .home-hero {
    width: 100%;
    height: 380px;
    flex-direction: column-reverse;
  }
  .home-text3 {
    width: 80%;
    align-self: center;
    text-align: left;
  }
  .home-hero1 {
    width: 100%;
    height: 380px;
    flex-direction: column-reverse;
  }
  .home-text4 {
    width: 80%;
    align-self: center;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .home-heading-container {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .home-video {
    height: 200px;
  }
  .home-hero-text {
    width: 100%;
  }
  .home-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .home-hero {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-hero1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
}
