.portfolio-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.portfolio-content-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.portfolio-content-container2 {
  flex: 0 0 auto;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: flex-start;
}
.portfolio-content-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.portfolio-content-container3 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 30px;
}
.portfolio-content-container4 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.portfolio-content-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.portfolio-content-container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
}
.portfolio-content-icon {
  width: 54px;
  height: 54px;
  margin-right: var(--dl-space-space-doubleunit);
}
.portfolio-content-icon2 {
  width: 54px;
  height: 54px;
}
.custom-color-synes .portfolio-content-icon,
.custom-color-synes .portfolio-content-icon2 {
  fill: #edafd4;
}
.custom-color-qtcarter .portfolio-content-icon,
.custom-color-qtcarter .portfolio-content-icon2 {
  fill: #f58f29;
}

.custom-color-xzyl .portfolio-content-icon,
.custom-color-xzyl .portfolio-content-icon2 {
  fill: #d04fcf;
}
.custom-color-wasabi .portfolio-content-icon,
.custom-color-wasabi .portfolio-content-icon2 {
  fill: #00b6bc;
}
.custom-color-namdori .portfolio-content-icon,
.custom-color-namdori .portfolio-content-icon2 {
  fill: #cdb7f6;
}
.custom-color-jaquita .portfolio-content-icon,
.custom-color-jaquita .portfolio-content-icon2 {
  fill: #FAD02C;
}
.custom-color-mumoxxo .portfolio-content-icon,
.custom-color-mumoxxo .portfolio-content-icon2 {
  fill: #21897e;
}
.custom-color-freckles .portfolio-content-icon,
.custom-color-freckles .portfolio-content-icon2 {
  fill: #bfe25f;
}
.custom-color-majahk .portfolio-content-icon,
.custom-color-majahk .portfolio-content-icon2 {
  fill: #ffca3a;
}
.custom-color-lowloco .portfolio-content-icon,
.custom-color-lowloco .portfolio-content-icon2 {
  fill: #00c2ab;
}
.custom-color-heidi .portfolio-content-icon,
.custom-color-heidi .portfolio-content-icon2 {
  fill: #da8eff;
}
.custom-color-borch .portfolio-content-icon,
.custom-color-borch .portfolio-content-icon2 {
  fill: #39A7FF;
}
.custom-color-astra .portfolio-content-icon,
.custom-color-astra .portfolio-content-icon2 {
  fill: #c0c0c0;
}


.portfolio-content-container7 {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}
.portfolio-content-text2 {
  align-self: center;
  text-align: left;
}
.portfolio-content-text2 span {
  font-weight: bold;
}
.portfolio-content-root-class-name {
  align-self: flex-start;
}

@media (max-width: 479px) {
  .d-profile-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
    display: flex;
    flex-direction: column;
  }

  .portfolio-content-container1 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .portfolio-content-container3 {
    margin-left: 0;
  }
}