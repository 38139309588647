.mix-player-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.mix-player-div {
  width: 100%;
  height: 100%;
  align-self: center;
}

.mix-player-root-class-name1 {
  align-self: center;
}
