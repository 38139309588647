:root {
  --dl-color-landing: #ffffff; /*landing text*/
  --dl-color-section-sep: #4eb42f; /*section separation*/
  --dl-color-main: #19ed8b; /*Heading + link*/
  --dl-color-main-bg: #000; /*background color*/
  --dl-space-space-unit: 8px;
  --dl-radius-radius-round: 50%;
  --dl-size-size-maxcontent: 1414px;
  --dl-space-space-halfunit: 4px;
  --dl-space-space-tenunits: 80px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 40px;
  --dl-space-space-fourunits: 32px;
  --dl-radius-radius-radius24: 24px;
  --dl-space-space-doubleunit: 16px;
  --dl-space-space-tripleunit: 24px;
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.button {
  color: var(--dl-color-main);
  display: inline-block;
  padding: 0.5rem 1rem;
  font-family: Raleway;
  border-color: var(--dl-color-main);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-main-bg);
}
.textarea {
  color: var(--dl-color-main);
  cursor: auto;
  padding: 0.5rem;
  font-family: Raleway;
  border-color: var(--dl-color-main);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-main-bg);
}
.input {
  color: var(--dl-color-main);
  cursor: auto;
  padding: 0.5rem 1rem;
  font-family: Raleway;
  border-color: var(--dl-color-main);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-main-bg);
}
.Section-Text {
  font-size: 18px;
  font-family: "neue-haas-unica", sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
  color: var(--dl-color-landing);
}
.SmallCard-Heading {
  font-size: 18px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Text {
  font-size: 16px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.BigCard-Heading {
  font-size: 36px;
  font-style: normal;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Card-Heading {
  font-size: 24px;
  font-style: normal;
  font-family: Raleway;
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.Anchor {
  font-size: 18px;
  font-style: normal;
  font-family: "jetlab-variable", sans-serif;
  font-variation-settings: "THRU" 100, "VERT" 0, "HORZ" 0, "LIFT" 50;
  font-weight: 700;
  line-height: 1.55;
  text-decoration: none;
}
.footer-container .Anchor {
  font-family: "neue-haas-unica", sans-serif;
}
.Section-Heading {
  font-size: 48px;
  font-family: "jetlab-variable", sans-serif;
  font-variation-settings: "THRU" 100, "VERT" 0, "HORZ" 0, "LIFT" 50;
  font-weight: 700;
  line-height: 1.22;
  text-transform: none;
  text-decoration: none;
}
