.events-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  align-content: center;
  justify-content: center;

  flex-direction: column;
}
.events-video {
  width: 100%;
  height: 137px;
  position: relative;
  border-radius: var(--dl-radius-radius-radius8);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.event-poster-main {
  width: 60%;
  height: 60%;
  padding-bottom: 30px;
}

.events-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.events-heading {
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.events-text {
  color: var(--dl-color-landing);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.events-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.events-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.events-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.events-text1 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.events-text2 {
  color: var(--dl-color-landing);
  width: 75%;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.events-image {
  width: 80%;
  height: 80%;
  object-fit: cover;
  padding-top: 50px;
}
.events-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.events-container2-container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.events-container2 a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.events-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.events-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.events-hero {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-image: url("brohaven_tiny.png");
  background-repeat: no-repeat;
  background-position: center;
}
.events-text3 {
  margin-bottom: var(--dl-space-space-tripleunit);
}
.events-hero1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-image: url("homecoming_tiny.png");
  background-repeat: no-repeat;
  background-position: center;
}
.events-text4 {
  margin-bottom: var(--dl-space-space-tripleunit);
}

@media (max-width: 991px) {
  .events-video {
    height: 382px;
  }
  .events-hero-text {
    width: 80%;
  }
  .events-text2 {
    width: 80%;
  }
  .events-hero {
    width: 100%;
    height: 380px;
    flex-direction: column-reverse;
  }
  .events-text3 {
    width: 80%;
    align-self: center;
    text-align: left;
  }
  .events-hero1 {
    width: 100%;
    height: 380px;
    flex-direction: column-reverse;
  }
  .events-text4 {
    width: 80%;
    align-self: center;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .events-heading-container {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .events-video {
    height: 200px;
  }
  .events-hero-text {
    width: 100%;
  }
  .events-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .events-hero {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .events-hero1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
}
