.about-us-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-us-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.Section-Sub-Heading {
  font-family: "condor-compressed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 44px;
}
.about-us-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.about-us-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.about-us-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.about-us-text1 {
  color: var(--dl-color-landing);
  width: 75%;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.about-us-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.about-us-features1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.about-us-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.about-us-heading-container1 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.about-us-text2 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.about-us-text3 {
  color: var(--dl-color-landing);
  width: 75%;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.about-us-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.about-us-features2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.about-us-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.about-us-heading-container2 {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.about-us-text4 {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.about-us-text5 {
  color: var(--dl-color-landing);
  width: 75%;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.about-us-section-separator2 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
@media (max-width: 991px) {
  .about-us-text1 {
    width: 80%;
  }
  .about-us-text3 {
    width: 80%;
  }
  .about-us-text5 {
    width: 80%;
  }
}
@media (max-width: 767px) {
  .about-us-heading-container {
    width: 100%;
  }
  .about-us-heading-container1 {
    width: 100%;
  }
  .about-us-heading-container2 {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .about-us-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-us-features1 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .about-us-features2 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
