.event-page-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.event-page-hero {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  justify-content: center;
  background-image: url("homecoming_tiny.png");
  background-repeat: no-repeat;
  background-position: center;
}
.event-page-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.event-page-heading {
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.event-page-container1 {
  flex: 0 0 auto;
  width: 55%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.event-page-image {
  width: 15px;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.event-page-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.event-page-container2 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.event-poster {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
}
.event-page-link {
  display: contents;
}
.event-page-component1 {
  text-decoration: none;
}
.event-page-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}

.section-separator-recktangle {
  height: 500px;
  width: 200px;
  background-color: #19ed8b;
  margin: 20px;
  padding: 5px;
}
@media (max-width: 991px) {
  .event-page-hero,
  .event-page-hero1 {
    width: 100%;
    height: auto;
    flex-direction: column-reverse;
  }
  .event-page-hero-text {
    width: 80%;
  }
}
@media (max-width: 479px) {
  .event-page-hero {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .event-page-hero-text {
    width: 100%;
  }
  .event-page-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }

  .section-separator-recktangle {

    height: 10px;
    width: 70%px;
    background-color: #19ed8b;
    margin: 20px;

  }

  .event-page-container1 {
    display: flex;
    flex-direction: column;
    width: 75%;
    align-items: center;
  }
}
