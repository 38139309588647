.footer-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.footer-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
  border-top: #ffffff solid 1px;
}
.footer-copyright-container {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.custom-color-synes .footer-copyright-container {
  color: #edafd4;
}
.custom-color-qtcarter .footer-copyright-container {
  color: #f58f29;
}

.custom-color-xzyl .footer-copyright-container {
  color: #d04fcf;
}

.custom-color-wasabi .footer-copyright-container {
  color: #00b6bc;
}
.custom-color-jaquita .footer-copyright-container {
  color: #FAD02C;
}
.custom-color-mumoxxo .footer-copyright-container {
  color: #21897e;
}
.custom-color-namdori .footer-copyright-container {
  color: #cdb7f6;
}
.custom-color-freckles .footer-copyright-container {
  color: #bfe25f;
}
.custom-color-majahk .footer-copyright-container {
  color: #ffca3a;
}
.custom-color-lowloco .footer-copyright-container {
  color: #00c2ab;
}
.custom-color-heidi .footer-copyright-container {
  color: #da8eff;
}
.custom-color-borch .footer-copyright-container {
  color: #39A7FF;
}
.custom-color-astra .footer-copyright-container {
  color: #c0c0c0;
}

.footer-icon {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
  fill: #19ed8b;
}
.custom-color-synes .footer-icon {
  fill: #edafd4;
}
.custom-color-qtcarter .footer-icon {
  fill: #f58f29;
}
.custom-color-xzyl .footer-icon {
  fill: #d04fcf;
}
.custom-color-wasabi .footer-icon {
  fill: #00b6bc;
}
.custom-color-jaquita .footer-icon {
  fill: #FAD02C;
}
.custom-color-mumoxxo .footer-icon {
  fill: #21897e;
}
.custom-color-namdori .footer-icon {
  fill: #cdb7f6;
}
.custom-color-freckles .footer-icon {
  fill: #bfe25f;
}
.custom-color-majahk .footer-icon {
  fill: #ffca3a;
}
.custom-color-lowloco .footer-icon {
  fill: #00c2ab;
}
.custom-color-heidi .footer-icon {
  fill: #da8eff;
}
.custom-color-borch .footer-icon {
  fill: #39A7FF;
}
.custom-color-astra .footer-icon {
  fill: #c0c0c0;
}

.footer-social-links {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  color: #19ed8b;
}
.footer-icon2 {
  width: 24px;
  height: 24px;
  margin-right: var(--dl-space-space-doubleunit);
  color: #19ed8b;
}
.footer-icon4 {
  width: 24px;
  height: 24px;
  color: #19ed8b;
}

@media (max-width: 991px) {
  .footer-container {
    height: auto;
  }
  .footer-footer {
    height: 200%;
  }
}
