.about-us-image-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.about-us-image-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
