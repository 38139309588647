.navbar-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-navbar {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  background-color: #000000;
  border-bottom: #fff solid 1px;
}
.navbar-left-side {
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-image {
  fill: #19ed8b;
  fill-rule: evenodd;
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.custom-color-synes .navbar-image {
  fill: #edafd4;
}

.custom-color-qtcarter .navbar-image {
  fill: #f58f29;
}
.custom-color-xzyl .navbar-image {
  fill: #d04fcf;
}
.custom-color-wasabi .navbar-image {
  fill: #00b6bc;
}
.custom-color-jaquita .navbar-image {
  fill: #FAD02C;
}
.custom-color-mumoxxo .navbar-image {
  fill: #21897e;
}
.custom-color-namdori .navbar-image {
  fill: #cdb7f6;
}
.custom-color-freckles .navbar-image {
  fill: #bfe25f;
}
.custom-color-majahk .navbar-image {
  fill: #ffca3a;
}
.custom-color-lowloco .navbar-image {
  fill: #00c2ab;
}
.custom-color-heidi .navbar-image {
  fill: #da8eff;
}
.custom-color-borch .navbar-image {
  fill: #39A7FF;
}
.custom-color-astra .navbar-image {
  fill: #c0c0c0;
}
.navbar-burger-menu {
  display: none;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  cursor: pointer;
}
.navbar-icon {
  width: 36px;
  height: 36px;
  fill: var(--dl-color-main-bg);
}
.navbar-burger-menu {
  background-color: var(--dl-color-main);
}
.custom-color-synes .navbar-burger-menu {
  background-color: #edafd4;
}

.custom-color-qtcarter .navbar-burger-menu {
  background-color: #f58f29;
}
.custom-color-xzyl .navbar-burger-menu {
  background-color: #d04fcf;
}
.custom-color-wasabi .navbar-burger-menu {
  background-color: #00b6bc;
}
.custom-color-jaquita .navbar-burger-menu {
  background-color: #FAD02C;
}
.custom-color-mumoxxo .navbar-burger-menu {
  background-color: #21897e;
}
.custom-color-namdori .navbar-burger-menu {
  background-color: #b1a0ef;
}
.custom-color-freckles .navbar-burger-menu {
  background-color: #bfe25f;
}
.custom-color-majahk .navbar-burger-menu {
  background-color: #ffca3a;
}
.custom-color-lowloco .navbar-burger-menu {
  background-color: #00c2ab;
}
.custom-color-heidi .navbar-burger-menu {
  background-color: #da8eff;
}
.custom-color-borch .navbar-burger-menu {
  background-color: #39A7FF;
}
.custom-color-astra .navbar-burger-menu {
  background-color: #c0c0c0;
}

.navbar-links-container {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-link {
  color: #19ed8b;
  margin-right: var(--dl-space-space-tripleunit);
  text-decoration: none;
}
.custom-color-synes .navbar-link {
  color: #edafd4;
}
.custom-color-qtcarter .navbar-link {
  color: #f58f29;
}
.custom-color-xzyl .navbar-link {
  color: #d04fcf;
}
.custom-color-wasabi .navbar-link {
  color: #00b6bc;
}
.custom-color-jaquita .navbar-link {
  color: #FAD02C;
}
.custom-color-mumoxxo .navbar-link {
  color: #21897e;
}
.custom-color-namdori .navbar-link {
  color: #cdb7f6;
}
.custom-color-freckles .navbar-link {
  color: #bfe25f;
}
.custom-color-majahk .navbar-link {
  color: #ffca3a;
}
.custom-color-lowloco .navbar-link {
  color: #00c2ab;
}
.custom-color-heidi .navbar-link {
  color: #da8eff;
}
.custom-color-borch .navbar-link {
  color: #39A7FF;
}
.custom-color-astra .navbar-link {
  color: #c0c0c0;
}

.navbar-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  position: fixed;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  z-index: 999999;
}
.navbar-mobile-menu.is-open {
  display: block;
}
.navbar-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.navbar-image1 {
  width: 50px;
  align-self: center;
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.navbar-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
}
.navbar-icon2 {
  width: 24px;
  height: 24px;
}
.navbar-links-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-link4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-link5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-link6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-link7 {
  text-decoration: none;
}

@media (max-width: 991px) {
  .navbar-root-class-name {
    height: 100%;
  }
  .navbar-root-class-name1 {
    height: 100%;
  }
  .navbar-root-class-name2 {
    height: 100%;
  }
  .navbar-root-class-name3 {
    height: 100%;
  }
  .navbar-root-class-name4 {
    height: 100%;
  }
  .navbar-root-class-name5 {
    height: 100%;
  }
  .navbar-root-class-name6 {
    height: 100%;
  }
  .navbar-root-class-name7 {
    height: 100%;
  }
  .navbar-root-class-name8 {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .navbar-burger-menu {
    display: flex;
    padding: var(--dl-space-space-halfunit);
    background-color: var(--dl-color-main);
  }
  .navbar-links-container {
    display: none;
  }
}
