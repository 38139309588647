.contact-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}

.contact-hero-text {
  width: 65%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact-heading {
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.contact-text {
  color: var(--dl-color-landing);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.contact-text1 {
  color: var(--dl-color-landing);
  width: 75%;
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.contact-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #19ed8b;
}
.contact-icon {
  width: 54px;
  height: 54px;
  margin-right: var(--dl-space-space-doubleunit);
  color: #19ed8b;
}
.contact-icon2 {
  width: 54px;
  height: 54px;
  color: #19ed8b;
}
.contact-features1 {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.contact-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
@media (max-width: 991px) {
  .contact-hero-text {
    width: 80%;
  }
  .contact-text1 {
    width: 80%;
  }
}
@media (max-width: 479px) {
  .contact-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
  .contact-hero-text {
    width: 100%;
  }
  .contact-features1 {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
