.d-profile-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  color: white;
}
.d-profile-container.custom-color-synes h1 {
  color: #edafd4;
}
.d-profile-container.custom-color-qtcarter h1 {
  color: #f58f29;
}

.d-profile-container.custom-color-xzyl h1 {
  color: #d04fcf;
}

.d-profile-container.custom-color-wasabi h1 {
  color: #00b6bc;
}
.d-profile-container.custom-color-jaquita h1 {
  color: #FAD02C;
}
.d-profile-container.custom-color-mumoxxo h1 {
  color: #21897e;
}
.d-profile-container.custom-color-namdori h1 {
  color: #cdb7f6;
}
.d-profile-container.custom-color-freckles h1 {
  color: #bfe25f;
}
.d-profile-container.custom-color-majahk h1 {
  color: #ffca3a;
}
.d-profile-container.custom-color-lowloco h1 {
  color: #00c2ab;
}
.d-profile-container.custom-color-heidi h1 {
  color: #da8eff;
}
.d-profile-container.custom-color-borch h1 {
  color: #39A7FF;
}
.d-profile-container.custom-color-astra h1 {
  color: #c0c0c0;
}

.d-profile-features {
  width: 100%;
  height: auto;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.d-profile-container1 {
  gap: 80px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  justify-content: center;
}
.d-profile-container2 {
  gap: 80px;
  flex: 0 0 auto;
  max-width: min(100%, 755px);
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.d-profile-container3 {
  flex: 0 0 auto;
  max-width: min(100%, 755px);
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}
.d-profile-container4 {
  width: 70%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-profile-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
@media (max-width: 479px) {
  .d-profile-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
    display: block;
  }
  .portfolio-content-icon, .portfolio-content-icon2 {
    width: 40px;
    height: 40px;
  }
  h1 {
    font-size: 1.7em;
  }

}
