.ourcrew-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.ourcrew-image-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ourcrew-image-text {
  font-style: normal;
  font-family: "jetlab-variable", sans-serif;
  font-variation-settings: "THRU" 100, "VERT" 0, "HORZ" 0, "LIFT" 50;
}
@media (max-width: 991px) {
  .ourcrew-image-image {
    width: 100%;
    height: 100%;
  }
  .ourcrew-image-text {
    align-self: center;
    padding-top: 15px;
    font-size: 20px;
    font-family: "jetlab-variable", sans-serif;
    font-variation-settings: "THRU" 100, "VERT" 0, "HORZ" 0, "LIFT" 50;
  }
}
@media (max-width: 650px) {
  .ourcrew-image-text {
    padding-top: 15px;
    font-size: 13px;
    font-family: "jetlab-variable", sans-serif;
    font-variation-settings: "THRU" 100, "VERT" 0, "HORZ" 0, "LIFT" 50;
  }
}
