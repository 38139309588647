.our-crew-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.our-crew-features {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.our-crew-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.our-crew-heading-container {
  width: 45%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.our-crew-text {
  text-align: center;
  margin-bottom: var(--dl-space-space-fourunits);
}
.our-crew-text1 {
  color: var(--dl-color-landing);
  text-align: center;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.our-crew-section-separator {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.our-crew-section-separator1 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.our-crew-container2 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.our-crew-image-text {
  font-family: "jetlab-variable", sans-serif;
  font-variation-settings: "THRU" 100, "VERT" 0, "HORZ" 0, "LIFT" 50;
}
.our-crew-container3 {
  gap: 20px;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.our-crew-container4 {
  gap: var(--dl-space-space-tripleunit);
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.our-crew-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: flex-start;
}
.our-crew-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
.our-crew-section-separator3 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-section-sep);
}
@media (max-width: 991px) {
  .our-crew-text1 {
    width: 80%;
  }
  .our-crew-container2 {
    gap: 20px;
    width: auto;
    height: 100%;
    margin: 5px;
    align-self: stretch;
    flex-direction: row;
  }
  .our-crew-container3 {
    gap: 20px;
    width: auto;
    height: 100%;
    margin: 5px;
    align-self: stretch;
    flex-direction: row;
  }
  .our-crew-container4 {
    gap: 20px;
    width: auto;
    height: 100%;
    margin: 5px;
    align-self: stretch;
    flex-direction: row;
  }
  .our-crew-container5 {
    height: 100%;
    align-self: center;
  }
  .our-crew-section-separator3 {
    height: 49px;
  }
}
@media (max-width: 767px) {
  .our-crew-heading-container {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .our-crew-features {
    padding-top: var(--dl-space-space-fiveunits);
    padding-bottom: var(--dl-space-space-fiveunits);
  }
}
